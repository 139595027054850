import "./src/styles/style.css";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://b6c88f5265039f7ab65e3c7dc58ff9d0@o4508301035831296.ingest.de.sentry.io/4508301039894608",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  pathname,
  getSavedScrollPosition,
}) => {
  const saveScrollPaths = ["/kurs-og-konferanser/", "/aktuelt/"];

  const checkPathname = (path) => {
    return saveScrollPaths.some((x) => path?.includes(x));
  };

  if (
    pathname === "/" ||
    (checkPathname(pathname) &&
      !checkPathname(prevRouterProps?.location?.pathname))
  ) {
    const queriedPosition = getSavedScrollPosition(routerProps.location);
    if (window.innerWidth > 768) {
      setTimeout(() => {
        window.scrollTo(0, queriedPosition[1]);
      }, 500);
    } else {
      setTimeout(() => {
        window.scrollTo(0, queriedPosition[1]);
      }, 800);
    }
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }

  return false;
};
