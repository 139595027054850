exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelt-js": () => import("./../../../src/pages/aktuelt.js" /* webpackChunkName: "component---src-pages-aktuelt-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foreninger-js": () => import("./../../../src/pages/foreninger.js" /* webpackChunkName: "component---src-pages-foreninger-js" */),
  "component---src-pages-sok-js": () => import("./../../../src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-templates-2-column-js": () => import("./../../../src/templates/2column.js" /* webpackChunkName: "component---src-templates-2-column-js" */),
  "component---src-templates-event-date-picker-js": () => import("./../../../src/templates/event-date-picker.js" /* webpackChunkName: "component---src-templates-event-date-picker-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-kurs-js": () => import("./../../../src/templates/kurs.js" /* webpackChunkName: "component---src-templates-kurs-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-stream-js": () => import("./../../../src/templates/stream.js" /* webpackChunkName: "component---src-templates-stream-js" */)
}

