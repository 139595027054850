import fetch from "cross-fetch";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.GATSBY_WORDPRESS_GRAPHQL_URL,
    fetch,
    credentials: "include",
  }),
});

export default client;
